import { ContextMenu } from './context-menu.js'
import { switchCellVisual } from './swith-cell-visual'
import { OpenDialog } from './dialog'
import { paramModelCellGetShapes } from './param-model.js'
import { contextGetParam } from './context.js'
import { paramGetGraph } from './param.js'
import { paramGraphGetElement } from './param-graph.js'


export const displayCellContextMenu = (context, cellView, event, x, y) => {
  const cell = cellView.model
  const items = []
  const type = cell.get('type')
  const cellId = cell.get('id')
  const param = contextGetParam(context)
  const paramGraph = paramGetGraph(param)
  const paramModelCell = context.shapeTypeModelMap[type]
  const paramShape = context.shapeMap[type]
  const shapes = paramModelCellGetShapes(paramModelCell)
  if (shapes.length > 1) {
    const nameTypes = shapes.map(shape => shape.type)
    const nextType = nameTypes[(nameTypes.indexOf(type) + 1) % nameTypes.length]
    items.push({
      action: 'switchVisual',
      content: 'Switch visual',
      actionFunction: () => switchCellVisual(context, cell, nextType)
    })
  }

  if (cell.isLink()) {
    if (event.target.getAttribute('class') == 'marker-vertex') {
      items.push({
          action: 'unbendLine',
          content: 'Unbend line',
          actionFunction: function () {
            cell.removeVertex(event.target.getAttribute('idx'))
          }
      })
    } else {
      items.push({
        action: 'bendLine',
        content: 'Bend line' ,
        actionFunction: function () {
          cellView.addVertex({
              x: x,
              y: y
          })
        }
      })
    }

    const labels = cell.labels()
    if (labels.length !== 0) {
      items.push({
        action: 'rename',
        content: 'Rename' ,
        actionFunction: async () => {
          const path = 'labels/0/attrs/text/text'
          const name = cell.prop(path)
          const newName = await OpenDialog({value: name, position: {x: event.pageX, y: event.pageY}})
          if (name !== newName) {
            cell.prop(path, newName)
          }
        }
      })
    }
  } else {
    if (paramShape.textSelector || paramShape.textProperty) {
      const textProperty = paramShape.textProperty || paramShape.textSelector + '/text'
      items.push({
        action: 'rename',
        content: 'Rename' ,
        actionFunction: async () => {
          const name = cell.attr(textProperty)
          const newName = await OpenDialog({value: name, position: {x: event.pageX, y: event.pageY}})
          if (name !== newName) {
            cell.attr(textProperty, newName)
          }
        }
      })
    }
  }

  
  items.push({
    action: 'delete',
    content: 'Delete',
    actionFunction: () => cell.remove()
  })



  if (items.length == 0) { // If there are not items in context menu exit
    return
  }

  const contextMenu = new ContextMenu({
    items: items,
    target: cellView.$el,
    position: {x: event.pageX, y: event.pageY}
  })
  items.forEach(item => {
    const action = 'action:' + item.action
    const actionFunction = item.actionFunction
    contextMenu.on(action, actionFunction)
  })
  contextMenu.render()
}